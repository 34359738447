<template>
  <div id="login">
    <div class="login login-sign">
      <el-form label-position="right" label-width="80px" :model="loginForm">
        <el-form-item label="学号/工号">
          <el-input v-model="loginForm.email" placeholder="请输入学号/工号"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="loginForm.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
      </el-form>
      <input @click="login()" type="button" class="login-btn" value="登录" />
    </div>
  </div>
</template>

<script>
import jwt from 'jwt-decode';
import {adminLogin} from '../../service/getData';
export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      judgeEmail: false,
      password: '',
      loginClick: true,
      loginForm: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    //登录
    login() {
      adminLogin(this.loginForm.email, this.loginForm.password).then(
        (data) => {
          if (data.code === 200) {
            this.$message({
              type: 'success',
              message: data.message
            });
            let _this = this;
            // console.log('登录成功的数据是', data.object.token);
            this.$store.dispatch('token', data.object.token);
            let {role} = jwt(data.object.token);
            // setTimeout(function () {
            //   if (role === '1') {
            //     _this.$router.push({
            //       name: 'ClassifyManagement'
            //     });
            //   } else {
            //     _this.$router.push({
            //       name: 'IntroducePage'
            //     });
            //   }
            // }, 1000);
            if (role === '1') {
              _this.$router.replace({
                name: 'ClassifyManagement'
              });
            } else {
              _this.$router.replace({
                name: 'IntroducePage'
              });
            }
          } else {
            this.$message({
              type: 'error',
              message: data.message
            });
          }
        },
        (error) => {
          console.log(error);
          this.$message('未知错误');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#login {
  overflow: auto;
  margin: 20px auto;
  .login {
    position: relative;
    padding: 0 20px;
    width: 100%;

    // height: 350px;
    height: auto;
    .num {
      margin-top: 20px;
      height: 20px;
      font-size: 14px;
    }
    .login-ipt {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border: 0;
      border-bottom: 1px solid #777575;
      outline: none;
    }
    .forget {
      display: block;
      margin-top: 10px;
      text-align: right;
      text-decoration: none;
      color: #23a9f2;
    }
    .login-btn {
      position: relative;
      left: 50%;
      margin-bottom: 10px;

      // // position: absolute;
      // // top: 200px;
      // // left: 50%;
      // width: 50%;
      // height: 40px;
      // border: 0;
      // text-align: center;
      // color: #ffffff;
      // background-color: #23a9f2;
      // cursor: pointer;
      // transform: translateX(-50%);
      width: 50%;
      height: 40px;
      border: 0;
      text-align: center;
      color: #ffffff;
      background-color: #23a9f2;
      cursor: pointer;
      transform: translateX(-50%);
    }
    .login-btn:active {
      color: #000000;
      background-color: #d4d1d1;
    }
  }
}
</style>
